/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal content with increased width and height */
  .modal-content {
    background: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    max-width: 900px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  /* Modal header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    margin-top: -40px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .close-button-process-modal {
    background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .close-button-process-modal:hover {
    background: linear-gradient(90deg, #c82333 0%, #dc3545 100%);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
    transform: translateY(-2px);
  }
  
  /* Modal body layout */
  .modal-body {
    display: flex;
    gap: 20px;
    height: 60vh;
  }

  /* Right Section Styling (optional if not yet defined) */
  .modal-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  /* Updated Left Section Styling */
  .modal-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 25%;
    max-height: 60vh; /* Set a maximum height for the left side */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    padding-right: 10px; /* Add padding for a better scrolling experience */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }

/* Subsection Titles */
.modal-left h3 {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #4a5568;
}

.modal-left p{
  margin-bottom: -15px;
}

/* Buttons for each section */
.referral-letter-button,
.follow-up-letter-button,
.consultation-letter-button,
.prescription-button,
.soap-template-button,
.discharge-template-button,
.specialty-template-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}
/* Button Colors - keeping the original color variations for different button types */
.referral-letter-button {
  background: linear-gradient(90deg, #4f6b8b 0%, #8aa1bf 100%);
}
.referral-letter-button:hover {
  background: linear-gradient(90deg, #8aa1bf 0%, #4f6b8b 100%);
  transform: translateY(-2px);
}

.follow-up-letter-button {
  background: linear-gradient(90deg, #008080 0%, #006666 100%);
}
.follow-up-letter-button:hover {
  background: linear-gradient(90deg, #006666 0%, #008080 100%);
  transform: translateY(-2px);
}

.consultation-letter-button {
  background: linear-gradient(90deg, #e69676 0%, #e07a5f 100%);
}
.consultation-letter-button:hover {
  background: linear-gradient(90deg, #e07a5f 0%, #e69676 100%);
  transform: translateY(-2px);
}

.prescription-button {
  background: linear-gradient(90deg, #ffd27f 0%, #ffb84d 100%);
}
.prescription-button:hover {
  background: linear-gradient(90deg, #ffb84d 0%, #ffd27f 100%);
  transform: translateY(-2px);
}

.soap-template-button {
  background: linear-gradient(90deg, #607d80 0%, #3a5357 100%);
}
.soap-template-button:hover {
  background: linear-gradient(90deg, #3a5357 0%, #607d80 100%);
  transform: translateY(-2px);
}

.discharge-template-button {
  background: linear-gradient(90deg, #b1cbd4 0%, #90acb9 100%);
}
.discharge-template-button:hover {
  background: linear-gradient(90deg, #90acb9 0%, #b1cbd4 100%);
  transform: translateY(-2px);
}

.specialty-template-button {
  background: linear-gradient(90deg, #c3a8a8 0%, #a68c8c 100%);
}
.specialty-template-button:hover {
  background: linear-gradient(90deg, #a68c8c 0%, #c3a8a8 100%);
  transform: translateY(-2px);
}


/* Main textarea styling for note content */
.modal-textarea {
  width: 100%;
  height: 50%; /* Set height to 50% to split the right column */
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #e2e8f0;
  font-size: 1rem;
  color: #4a5568;
  background-color: #f7fafc;
  resize: none;
}

/* Additional information textarea styling */
.additional-info-textarea {
  width: 100%;
  height: 50%; /* Use remaining space in the right column */
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #e2e8f0;
  font-size: 1rem;
  color: #4a5568;
  background-color: #f7fafc;
  resize: none;
}
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .modal-content {
      padding: 25px;
    }
  
    .modal-body {
      flex-direction: column;
      height: auto;
    }
  
    .modal-left,
    .modal-right {
      width: 100%;
    }
  
    .modal-button {
      padding: 10px;
    }
  
    .modal-textarea {
      height: 200px;
    }
  }
  
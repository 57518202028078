/* Styling for the login container */
.login-container {
    display: flex;
    height: 100vh;
    font-family: system-ui, -apple-system, sans-serif;
    background-color: #f4f7f6;
}

/* Left half for the image section */
.image-section {
    flex: 1 1 50%;
    background-color: #7bbab6;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-section img {
    max-width: 80%;
    max-height: 90vh;
    object-fit: contain;
    border-radius: 0;
    /* Remove rounded corners */
    box-shadow: none;
    /* Remove shadow */
}

/* Right half for the form section */
.form-section {
    flex: 1 1 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
    border-radius: 0 20px 20px 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

/* Header Styling */
.form-section h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #1a1a1a;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
}

.form-section h1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #008080 0%, #aac7c6 100%);
    border-radius: 2px;
}

.form-section p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    color: #4a5568;
}

/* Input field styling */
.input-container {
    margin-bottom: 25px;
    position: relative;
    display: inline-block;
}

.input-container input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

/* Tooltip styling */
.tooltip {
    background-color: #008080;
    color: #fff;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 0.9rem;
    position: absolute;
    z-index: 1;
    opacity: 0.9;
    width: max-content;
    max-width: 200px;
    top: -45px;
    left: 0;
    white-space: normal;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    border-width: 8px;
    border-style: solid;
    border-color: #008080 transparent transparent transparent;
}

/* Button styling */
.form-section button {
    padding: 12px;
    background: linear-gradient(135deg, #008080 0%, #006666 100%);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
}

.form-section button:hover {
    background: linear-gradient(135deg, #006666 0%, #008080 100%);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }

    .image-section {
        display: none;
    }

    .form-section {
        border-radius: 20px;
        padding: 25px;
        margin: 20px;
    }

    .form-section h1 {
        font-size: 1.8rem;
    }

    .form-section button {
        padding: 10px 20px;
    }
}

.install-button {
    margin-bottom: 25px;
    padding: 12px;
    background: linear-gradient(90deg, #aac7c6 0%, #008080 100%);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
    width: 100%;
}

.install-button:hover {
    background: linear-gradient(90deg, #008080 0%, #aac7c6 100%);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
}

/* Home button styling */
.home-button {
    margin-top: 15px;
    padding: 12px;
    background: linear-gradient(135deg, #4a5568 0%, #2d3748 100%);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(42, 67, 101, 0.15);
    width: 100%;
}

.home-button:hover {
    background: linear-gradient(135deg, #2d3748 0%, #4a5568 100%);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(42, 67, 101, 0.2);
}
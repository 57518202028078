/* Container styling */
.top-left-note-viewer-container {
  background: linear-gradient(145deg, #ffffff 0%, #f8fafa 100%);
  padding: 40px;
  padding-top: 5px;
  padding-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  font-family: system-ui, -apple-system, sans-serif;
  
  margin: 0 auto;
  text-align: left;
}

/* Header styling */
.top-left-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding-bottom: 0px;
  margin-top: -20px;
 
}

.top-left-header h2 {
  font-size: 2rem;
  color: #1a1a1a;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.top-left-header h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #008080 0%, #aac7c6 100%);
  border-radius: 2px;
}

/* Refresh icon container styling */
.refresh-icon-container {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-icon {
  font-size: 1.5rem;
  color: #008080;
  transition: transform 0.3s ease;
}

.refresh-icon-container:hover .refresh-icon {
  transform: rotate(180deg);
}

.tooltip-text {
  position: absolute;
  top: -30px;
  background: #008080;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.refresh-icon-container:hover .tooltip-text {
  opacity: 1;
}

/* Notes container */
.notes-container {
  position: relative;
  margin-top: 15px;
  height: 300px;
  overflow: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

/* Table styling */
.notes-container table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  min-width: 400px;
  table-layout: fixed; /* Add this to enforce column widths */
}

/* Column width specifications */
.notes-container th:first-child,
.notes-container td:first-child {
  width: 25%; /* Adjust this value to change Note column width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notes-container th:last-child,
.notes-container td:last-child {
  width: 30%; /* Adjust this value to change Created At column width */
}

/* Sticky header styling */
.notes-container thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.notes-container th {
  background: linear-gradient(135deg, #008080 0%, #006666 100%);
  color: white;
  padding: 15px;
  text-align: left;
  font-weight: 600;
}

/* Add pseudo-element to prevent content showing through sticky header */
.notes-container thead::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 2px;
  background: linear-gradient(135deg, #008080 0%, #006666 100%);
}

/* Table body styling */
.notes-container td {
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  color: #4a5568;
  background-color: white;
}

.notes-container tbody tr:hover {
  background-color: #f8fafa;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Loading row and spinner styles */
.loading-row {
  opacity: 0.8;
}

.loading-spinner {
  display: flex;
  align-items: center;
  color: #008080;
}

.loading-spinner span {
  margin-left: 8px;
  font-style: italic;
}

.spinning {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .top-left-note-viewer-container {
    padding: 25px;
  }

  .top-left-header h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  .notes-container th, 
  .notes-container td {
    padding: 10px;
  }

  .notes-container table {
    min-width: unset;
  }
}
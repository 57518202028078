/* Overlay styling for Personal Information Modal */
.personal-info-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

/* Modal content styling */
.personal-info-modal-content {
    background-color: #ffffff;
    border-radius: 20px;
    width: 90%;
    max-width: 800px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 30px;
}

/* Header styling */
.personal-info-modal-content h2 {
    font-size: 2.5rem;
    color: #1a1a1a;
    font-weight: 700;
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
}

.personal-info-modal-content h2::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #008080 0%, #aac7c6 100%);
    border-radius: 2px;
}

/* Input group layout */
.personal-info-input-group {
    display: flex;
    gap: 0px;
    margin-top: 20px;
    flex-wrap: wrap;

}

.doctor-info,
.practice-info,
.template-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Label styling */
.personal-info-label {
    font-size: 1.1rem;
    color: #008080;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: left;
}

/* Textarea styling */
.personal-info-textarea {
    font-size: 1rem;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #eef2f2;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    resize: none;
    width: 90%;
    min-height: 100px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.personal-info-textarea:focus {
    border-color: #008080;
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.1);
    outline: none;
}

/* Button container styling */
.personal-info-modal-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 30px;
}

/* Save button styling */
.personal-info-save-button {
    background: linear-gradient(135deg, #008080 0%, #006666 100%);
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
}

.personal-info-save-button:hover {
    background: linear-gradient(135deg, #006666 0%, #008080 100%);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
    transform: translateY(-2px);
}

/* Close button styling */
.personal-info-close-button {
    background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
}

.personal-info-close-button:hover {
    background: linear-gradient(90deg, #c82333 0%, #dc3545 100%);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .personal-info-input-group {
        flex-direction: column;
    }

    .personal-info-modal-content h2 {
        font-size: 2rem;
        margin-bottom: 15px;
    }
}

/* Template select dropdown styling */
.template-select {
    flex: 2;
    width: 90%;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #eef2f2;
    font-size: 1rem;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    cursor: pointer;
}

.template-select:focus {
    border-color: #008080;
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.1);
    outline: none;
}

/* Template actions container */
.template-actions {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    width: 90%;
}

/* Store template button styling */
.store-template-button {
    flex: 1;
    background: linear-gradient(90deg, #aac7c6 0%, #008080 100%);
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
}

.store-template-button:hover {
    background: linear-gradient(90deg, #008080 0%, #aac7c6 100%);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
    transform: translateY(-2px);
}

/* Store template modal styling */
.store-template-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
    z-index: 1100;
}

.store-template-modal input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #eef2f2;
    font-size: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.store-template-modal input:focus {
    border-color: #008080;
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.1);
    outline: none;
}

/* Store template modal buttons */
.store-template-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.store-template-buttons button {
    padding: 10px 25px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.store-template-buttons button:first-child {
    background: linear-gradient(135deg, #008080 0%, #006666 100%);
    color: white;
}

.store-template-buttons button:last-child {
    background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
    color: white;
}

.store-template-buttons button:hover {
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
    transform: translateY(-2px);
}

/* Error message styling */
.error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin-bottom: 15px;
}

.template-controls {
    display: flex;
    gap: 15px;
    width: 90%;
    margin-top: 15px;
}

/* Delete button styling */
.delete-template-button {
    background: linear-gradient(90deg, #dc3545 0%, #c82333 100%);
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 128, 128, 0.15);
}

.delete-template-button:hover {
    background: linear-gradient(90deg, #c82333 0%, #dc3545 100%);
    box-shadow: 0 6px 15px rgba(0, 128, 128, 0.2);
    transform: translateY(-2px);
}